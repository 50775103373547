#header {
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  z-index: 100;

  .logo-wrapper img {
    height: 90px;
    width: auto;
  }
}