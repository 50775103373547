.strip {
  background-color: $blue;
  color: white;
  padding: 100px 0;

  h2 {
    margin-bottom: 10px;
  }
}

#disclaimer {
  padding: 100px 0;

  h2 {
    margin-bottom: 25px;
  }

  ul {
    li {
      margin-bottom: 15px;
    }
  }
}