#blog-header {
  background-color: $blue;
  color: white;
  padding: 100px 0;
}

#blog-posts {
  padding: 100px 0;

  .post-item {
    h3 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  img {
    width: 100%;
  }

  .btn {
    border-radius: 50px;
    padding: 8px 40px;
    font-size: 15px;
  }

  .pagination-wrapper {
    margin-top: 50px;
  }

  .pagination {
    li {
      margin-right: 10px;

      &.prev {
        a {
          width: auto;
          border-radius: 0;
          border-radius: 80px;
          padding: 16px 26px;
        }
      }

      &.next {
        a {
          width: auto;
          border-radius: 0;
          border-radius: 80px;
          padding: 16px 26px;
        }
      }
    }

    .page-link {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      color: black;

      &:hover,
      &.active {
        background-color: $blue;
        color: white;
        border: 1px solid $blue;
      }
    }
  }
}