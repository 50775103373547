#faq {
  background-color: $blue;
  color: white;
  padding: 100px 0;

  h2 {
    margin-bottom: 10px;
  }
}

#questions {
  padding: 100px 0;

  h2 {
    margin-bottom: 25px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }

    button {
      width: 100%;
      display: block;
      border-radius: 0;
      text-align: left;
    }

    div {
      padding: 25px 30px;
      border: 2px solid $blue;
    }
  }
}