@import "bootstrap/dist/css/bootstrap.min.css";
@import "font-awesome";
@import 'intl-tel-input/build/css/intlTelInput.css';
@import "~toastr/toastr";

@import "variables";

@import "typography";
@import "buttons";
@import "inputs";

@import "components/progress-bar";

@import "header";
@import "navbar";
@import "footer";
@import "pages/home";
@import "pages/about";
@import "pages/faq";
@import "pages/accessibility";
@import "pages/disclaimer";
@import "pages/terms";
@import "pages/privacy-policy";
@import "pages/blog";
@import "pages/contact-us";

.mx-w-full {
  max-width: 100%;
  max-height: 500px;
}

.mx-w-none {
  max-width: none;
  max-height: none;
}

