.iti.iti--allow-dropdown {
  width: 100%;

  input {
    width: 100%;
  }
}

.form-control,
.form-select {
  border-radius: 4px;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
  border: 0;
  min-height: calc(1.5em + 0.75rem + 10px);
  padding: 0.375rem 0.75rem;


  &:required:valid {
    box-shadow: 0 0 0 0.15rem rgb(0 158 68 / 70%);
  }

  &.error:invalid {
    box-shadow: 0 0 0 0.15rem rgb(253 13 13 / 25%);
  }
}

form {
  input[type="file"] {
    &:required:valid ~ label {
      box-shadow: 0 0 0 0.35rem rgb(0 158 68 / 70%);
    }

    &.error:invalid ~ label {
      box-shadow: 0 0 0 0.35rem rgb(253 13 13 / 25%);
    }
  }

  label {
    color: black;
    font-size: 16px;
    margin-bottom: 0 !important;
  }
}